<template>
    <div class="group-value" :class="cssClass">
        {{ adjustment.format(adjustment.value) }}

        <span v-if="adjustment.calculating">
            <v-progress-circular :size="15" :width="2" color="white" indeterminate />
        </span>
        <span v-else-if="adjustment.warning" class="warning-badge">
            <v-icon size="25" color="caution">mdi-alert</v-icon>
            <app-tooltip activator="parent">
                <span> {{ tooltip }}</span>
            </app-tooltip>
        </span>
    </div>
    <div class="group-label">{{ adjustment.title }}</div>
</template>

<script setup lang="ts">
    import { type AdjustmentInfo, type AdjustmentKey } from '@/planner/components/adjustments';
    import { computed } from 'vue';

    const props = defineProps<{
        adjustment: AdjustmentInfo;
        id: AdjustmentKey;
    }>();

    const cssClass = computed(() => {
        return {
            calculating: props.adjustment.calculating,
            warning: props.adjustment.warning,
        };
    });

    const tooltip = computed(() => {
        switch (props.id) {
            case 'stem-atv':
                return 'More than 10° from native femoral anteversion';
            case 'leg-length':
                return 'More than 10 mm from target';
            case 'leg-offset':
                return 'More than 10 mm from target';
            case 'cup-coverage':
                return 'Low cup coverage';
            default:
                return '';
        }
    });
</script>

<style scoped lang="scss">
    .group-value {
        font-weight: 700;
        font-size: 1rem;
        letter-spacing: 0.009375em;
        line-height: 1.75rem;
        color: rgb(var(--v-theme-white));

        &.warning {
            color: rgb(var(--v-theme-caution));
        }

        &.calculating {
            color: rgb(var(--v-theme-grey-light-1)) !important;
        }
    }

    .group-label {
        font-size: 0.75rem;
        letter-spacing: 0.0333333333em;
        line-height: 1.25rem;
        color: rgb(var(--v-theme-on-scene-background))
    }
</style>
