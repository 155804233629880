<template>
    <h4>
        <slot></slot>
    </h4>
</template>

<script setup lang="ts">
    import { toRefs } from 'vue';
    export interface Props {
        withTopSpacing?: boolean;
    }
    const props = defineProps<Props>();
    const { withTopSpacing } = toRefs(props);

    const topMargin = withTopSpacing.value ? '40px' : '0';
</script>

<style scoped>
    h4 {
        font-size: 21px;
        line-height: 34px;
        font-weight: 400;
        margin: v-bind('topMargin') 0 40px;
    }
</style>
