<template>
    <app-modal :model-value="props.value" persistent max-width="700">
        <v-card class="no-shadow">
            <!--            <v-card-title class="text-h5">-->
            <!--                <v-icon color="custom-orange" size="40" class="ml-0 ma-2">-->
            <!--                    mdi-alert-outline-->
            <!--                </v-icon>-->
            <!--                Download this Plan?-->
            <!--            </v-card-title>-->

            <app-modal-title @close="emit('close')">
                <v-icon color="planner-orange" size="40" class="ml-0 ma-2">
                    mdi-alert-outline
                </v-icon>
                Download this Plan?
            </app-modal-title>

            <app-modal-content>
                <p>Changes have been made in the 3D planner since this plan was produced.</p>

                <p>
                    If you need to produce a new plan including these changes, please
                    <router-link :to="plannerPath" class="text-decoration-none text-success">
                        return to the 3D planner
                    </router-link>
                    and click <b>&rsquo;Approve&rsquo;</b>, otherwise, click
                    <b>&rsquo;Download this Plan&rsquo;</b> below to continue.
                </p>
            </app-modal-content>

            <app-modal-actions>
                <app-button-secondary width="100" class="mr-5" @click="emit('close')">
                    Close
                </app-button-secondary>

                <router-link :to="plannerPath" class="text-decoration-none">
                    <app-button-primary class="text-uppercase mx-2" text>
                        Return to 3D Planner
                    </app-button-primary>
                </router-link>

                <v-spacer />

                <app-button-primary @click="emit('submit')"
                >
                    Download this plan
                </app-button-primary>
            </app-modal-actions>
        </v-card>
    </app-modal>
</template>

<script setup lang="ts">
    import { plannerRoute } from '@/router';
    import AppModalContent from '@/components/modal/AppModalContent.vue';
    import { computed } from 'vue';
    import { useCaseSyncStore } from '@/app/useCaseSyncStore';

    const emit = defineEmits(['submit', 'close']);
    const props = defineProps<{ value: boolean }>();

    const plannerPath = computed(() => {
        const caseId = useCaseSyncStore().case_?.id;
        return caseId ? plannerRoute(Number(caseId)) : '';
    });
</script>
