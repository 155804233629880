<template>
    <div
        style="text-align: right"
        v-if="
            (isCaseError || isCaseComplete || isCaseWarning) &&
            store.canUnassignSurgeon &&
            !store.isCaseDeemedUnsuitable
        "
    >
        <app-button
            color="red"
            data-testid="unassign-surgeon-button"
            :disabled="store.isCaseDeemedUnsuitable"
            @click="dialog = true"
        >
            Unassign Surgeon
        </app-button>

        <app-modal v-model="dialog" max-width="500" persistent class="bg-black">
            <v-card>
                <app-modal-title @click="dialog = false">Unassign Surgeon</app-modal-title>
                <app-modal-content>
                    <p>
                        Unassigning a surgeon from the Formus Hip case can be done if the case is
                        deemed clinically inappropriate. Please contact
                        <a href="mailto:support@formuslabs.com"> support@formuslabs.com </a>
                        to discuss the requirements.
                    </p>
                </app-modal-content>
                <app-modal-actions>
                    <app-button
                        color="red"
                        data-testid="cancel-unassign-surgeon"
                        @click="dialog = false"
                    >
                        Cancel
                    </app-button>
                    <app-button
                            data-testid="confirm-unassign-surgeon"
                        class="ml-4"
                        @click="onSubmit"
                    >
                        Unassign
                    </app-button>
                </app-modal-actions>
            </v-card>
        </app-modal>
    </div>
</template>

<script setup lang="ts">
    import { ref } from 'vue';
    import { useCaseSettings } from '@/caseSettings/store/store';
    import { useCaseSyncStore } from '@/app/useCaseSyncStore';

    const store = useCaseSettings();
    const { isCaseComplete, isCaseError, isCaseWarning } = useCaseSyncStore();

    const dialog = ref(false);

    const onSubmit = async () => {
        await store.unassignSurgeon();

        // When finished submitting request, close dialog.
        dialog.value = false;
    };
</script>
