<template>
    <div class="app-title-copy"><slot></slot></div>
</template>

<style scoped lang="scss">
    .app-title-copy {
        font-size: 14px;
        line-height: 23px;
        color: rgb(var(--v-theme-secondary));
        margin: 0 0 40px;
        max-width: 800px;
    }
</style>
