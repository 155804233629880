<template>
    <v-navigation-drawer
        absolute
        color="grey-transparent"
        v-model="drawer"
        :rail="rail"
        permanent
        floating
        class="planner-sidebar"
        :class="showContent ? 'drawer-expanded' : ''"
    >
        <!--    <v-navigation-drawer :rail="false" absolute  @update:rail="onRailOpen">-->
        <v-list-item
            class="mb-0 px-1 planner-sidebar-item"
            v-for="(item, index) in items"
            :key="index"
            :disabled="disableSidebar"
            @click="expandedPanel = item.key"
            :ripple="false"
        >
            <template #prepend>
                <v-hover>
                    <template v-slot:default="{ isHovering, props }">
                        <v-btn
                            v-bind="props"
                            elevation="0"
                            icon
                            :color="
                                expandedPanel === item.key
                                    ? 'white'
                                    : isHovering
                                      ? 'dark-blue'
                                      : 'planner-surface'
                            "
                            class="mr-4 pa-0"
                            height="44"
                            width="44"
                        >
                            <v-icon
                                :icon="
                                    expandedPanel === item.key
                                        ? item.icon
                                        : isHovering
                                          ? item.iconHover
                                          : item.icon
                                "
                                :width="item.width"
                                :height="item.height"
                            />
                        </v-btn>
                    </template>
                </v-hover>
                <v-list-item-title class="ml-5">{{ item.label }}</v-list-item-title>
            </template>
        </v-list-item>

        <template #append>
            <v-list-item
                class="mb-0 px-1 planner-sidebar-item"
                @click="onDevToolsClick"
                :disabled="disableSidebar"
                :ripple="false"
            >
                <v-btn
                    elevation="0"
                    icon
                    :color="devToolsSelected ? 'white' : 'grey-light-1'"
                    class="mr-0"
                    height="44"
                    width="44"
                >
                    <v-icon icon="mdi-cog" size="24px"></v-icon>
                </v-btn>
            </v-list-item>
        </template>
    </v-navigation-drawer>

    <!--
        Note: Using mobile: false
        If false, the component will be displayed in desktop mode always.
        This prevents the drawer from resizing when the app is in mobile (smaller media queries).
    -->
    <v-navigation-drawer
        v-if="showContent"
        absolute
        width="400"
        class=""
        color="grey-transparent"
        :mobile="false"
    >
        <v-sheet class="side-panel-container fill-height fluid pa-3">
            <div
                class="d-flex pt-0 pb-3 align-center ml-0 text-uppercase text-paragraph-sm text-secondary-text"
            >
                <div v-if="devToolsSelected">Dev tools</div>
                <div v-else-if="expandedPanel === 'stem'">Stem</div>
                <div v-else-if="expandedPanel === 'cup'">Cup</div>
                <div v-else-if="expandedPanel === 'combined'">Combined</div>

                <v-spacer />
                <!--                    <v-icon icon="f:collapse-lateral" size="16"></v-icon>-->

                <v-hover>
                    <template v-slot:default="{ isHovering, props }">
                        <v-btn
                            v-bind="props"
                            elevation="0"
                            icon
                            size="x-small"
                            height="40"
                            width="40"
                            :base-color="isHovering ? 'surface-light' : 'white'"
                            @click.stop="onClose"
                        >
                            <v-icon size="20" color="dark-blue">mdi-close</v-icon>
                            <!--                    <img :src="collapseLateral" class="" />-->
                        </v-btn>
                    </template>
                </v-hover>
            </div>

            <planner-status class="planner-status" />

            <v-overlay
                contained
                scrim="white"
                opacity="0.5"
                v-model="disabled"
                :aria-disabled="true"
            />

            <planner-sync-error />

            <v-container class="side-panel-container-form pb-0 px-0">
                <Form
                    v-slot="{ meta }"
                    ref="plannerForm"
                    class="fill-height d-flex flex-column w-100"
                >
                    <v-container
                        fluid
                        class="side-panel-container-form-scrollable px-4 pa-0"
                        :class="showChangesNotApprovedYet ? 'short' : 'long'"
                    >
                        <developer-tools
                            v-if="devToolsSelected && developerSettings.showDeveloperTools"
                        />
                        <stem-panel v-else-if="expandedPanel === 'stem'" />

                        <cup-panel v-else-if="expandedPanel === 'cup'" />

                        <combined-panel v-else-if="expandedPanel === 'combined'" />
                    </v-container>
                    <v-spacer />
                    <div class="side-panel-container-form-approve w-100 px-4">
                        <approve-plan
                            :valid="meta.valid"
                            @changes-not-approved-yet="onChangesNotApprovedYet"
                        />
                    </div>
                </Form>
            </v-container>
        </v-sheet>
    </v-navigation-drawer>
</template>

<script setup lang="ts">
    import { computed, ref } from 'vue';
    import StemPanel from '@/planner/components/StemPanel.vue';
    import { assert } from '@/util';
    import PlannerStatus from '@/planner/components/PlannerStatus.vue';
    import DeveloperTools from '@/planner/components/developerTools/DeveloperTools.vue';
    import { useDeveloperSettings } from '@/planner/developerSettings';
    import PlannerSyncError from '@/planner/components/PlannerSyncError.vue';
    import { Form } from 'vee-validate';
    import { usePlannerStore } from '@/planner/plannerStore';
    import CupPanel from '@/planner/components/CupPanel.vue';
    import ApprovePlan from '@/planner/components/ApprovePlan.vue';
    import CombinedPanel from '@/planner/components/CombinedPanel.vue';

    const store = usePlannerStore();
    const developerSettings = useDeveloperSettings();

    type SidebarPanelItem = {
        icon: string;
        iconHover: string;
        label: string;
        key: 'stem' | 'cup' | 'combined';
        width: string;
        height: string;
    };
    const items: SidebarPanelItem[] = [
        {
            iconHover: 'f:stem-light',
            icon: 'f:stem',
            label: 'Stem',
            key: 'stem',
            width: '27px',
            height: '32px',
        },
        {
            iconHover: 'f:cup-light',
            icon: 'f:cup',
            label: 'Cup',
            key: 'cup',
            width: '28px',
            height: '30px',
        },
        {
            iconHover: 'f:combined-light',
            icon: 'f:combined',
            label: 'Combined',
            key: 'combined',
            width: '42px',
            height: '42px',
        },
    ];

    const onClose = () => {
        // By setting expandedPanel to null, the planner mode will be set to 'default' view
        expandedPanel.value = null;
        rail.value = true;
    };

    const showChangesNotApprovedYet = ref(true);
    const onChangesNotApprovedYet = (value: boolean) => {
        showChangesNotApprovedYet.value = value;
    };

    const onDevToolsClick = () => {
        devToolsSelected.value = true;
        showContent.value = true;
    };

    const drawer = ref(true);
    const rail = ref(true);

    const showContent = ref(false);

    /**
     * True if the translucent white overlay should be shown, disabling input to the
     * main panel.
     *
     * Clicking on the overlay calls set(false), and logs a warning if the ref is not settable.
     * We do not want clicking to disable the overlay, so we make setting a no-op.
     */
    const disabled = computed({
        get: () => store.currentOperation !== null,
        set: () => {},
    });

    const disableSidebar = computed(() => store.currentOperation !== null);

    const devToolsSelected = ref(false);

    const expandedPanel = computed<'cup' | 'stem' | 'combined' | null>({
        get: () => {
            switch (store.plannerMode) {
                case 'default':
                case 'disabled':
                    return null;
                default:
                    return store.plannerMode;
            }
        },
        set: (value) => {
            devToolsSelected.value = false;
            showContent.value = !!value;

            assert(store.plannerMode !== 'disabled', 'planner-store is disabled');
            store.setMode(value ?? 'default');
        },
    });

    // const stemPanelDescription = computed<string>(() => {
    //     if (!stemStore.info) {
    //         return '';
    //     }
    //     const { stemProfile, stemOffset, stemSize, headOffset } = stemStore.info;
    //     const shortStemProfile = stemProfile === 'Reduced Distal' ? 'Reduced' : stemProfile;
    //     return `${shortStemProfile} ${stemOffset} ${stemSize} / ${formatNumberSign(headOffset)} Head`;
    // });
    //
    // const cupPanelDescription = computed<string>(() =>
    //     cupStore.selectedCupSize ? `Size ${cupStore.selectedCupSize}` : '',
    // );
</script>

<!--suppress CssUnusedSymbol -->
<style scoped lang="scss">
    .v-navigation-drawer.bg-grey-transparent {
        background-color: rgba(248, 248, 248, 0.87);
    }

    .v-navigation-drawer:not(.drawer-expanded) {
        --sidebar-close-width: 255px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    .planner-sidebar:deep(.v-navigation-drawer__content) {
        margin-top: 24px;
    }

    .planner-sidebar-item:hover {
        :deep(.v-list-item__overlay) {
            background-color: rgb(var(--v-theme-grey-light-1));
        }
    }

    .side-panel-container {
        padding: 0 12px;
        display: flex;
        flex-direction: column;
        background: none;
        top: 0;
        max-height: 100%;
        z-index: 20;

        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;

        .side-panel-container-form {
            background-color: rgb(var(--v-theme-surface-light));
            overflow-y: auto;
            height: calc(100%);
            margin-right: 10px;
            border-radius: 12px 12px 4px 4px;

            .side-panel-container-form-scrollable {
                overflow-y: auto;
                scrollbar-width: none;
                scrollbar-gutter: stable both-edges;

                &.long {
                    height: calc(100% - 96px);
                }

                &.short {
                    height: calc(100% - 64px);
                }
            }

            .side-panel-container-form-approve {
                position: relative;
                margin-bottom: 12px;
            }
        }
    }

    .planner-status {
        position: absolute;
        right: 60px;
        align-items: center;
        top: 20px;
        z-index: 3000;
    }

    .app-expansion-panels :deep(.v-expansion-panel__shadow) {
        box-shadow: none;
    }

    .app-expansion-panels :deep(.v-expansion-panel-title__overlay) {
        opacity: 0;
    }
</style>
