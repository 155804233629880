<template>
    <div class="snap-options-container">
        <div class="snap-options">
            <button @click="setCameraPlacement(planner, planner.plannerMode)">Default View</button>
            <button @click="setCameraPlacement(planner, 'coronal')">Coronal</button>
            <button @click="setCameraPlacement(planner, 'axial')">Axial</button>
            <button @click="setCameraPlacement(planner, 'lateral')">Lateral</button>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { usePlannerStore } from '@/planner/plannerStore';
    import { setCameraPlacement } from '@/planner/scene/cameraPlacement';

    const planner = usePlannerStore()
</script>

<style scoped lang="scss">
    .snap-options-container {
        display: flex;
    }

    .snap-options {
        display: flex;
        text-align: right;
        justify-content: flex-end;

        button {
            background: none;
            border: none;
            color: rgb(var(--v-theme-primary));
            cursor: pointer;
            font-size: 12px;
            outline: none;
            padding: 0;

            &:before {
                background: rgb(var(--v-theme-secondary));
                content: '';
                display: inline-block;
                height: 10px;
                margin-left: 4px;
                margin-right: 4px;
                position: relative;
                top: 1px;
                width: 1px;
            }

            &:first-of-type {
                &:before {
                    display: none;
                }
            }

            &:hover {
                color: #fff;
            }
        }
    }
</style>
