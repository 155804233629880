import { type App as Application } from 'vue';
import AppButton from '@/components/AppButton.vue';
import AppMainCard from '@/components/AppMainCard.vue';
import AppDivider from '@/components/AppDivider.vue';
import AppFormActions from '@/components/forms/AppFormActions.vue';
import AppFormActionNotification from '@/components/forms/AppFormActionNotification.vue';
import AppFormGroup from '@/components/forms/AppFormGroup.vue';
import AppFormSectionTitle from '@/components/forms/AppFormSectionTitle.vue';
import AppFormSubtitle from '@/components/forms/AppFormSubtitle.vue';
import AppFormTitle from '@/components/forms/AppFormTitle.vue';
import AppHeader from '@/app/AppHeader.vue';
import AppHeading from '@/components/AppHeading.vue';
import AppHint from '@/components/AppHint.vue';
import AppLink from '@/components/AppLink.vue';
import AppLoader from '@/app/AppLoader.vue';
import AppMainView from '@/components/AppMainView.vue';
import AppMenuItem from '@/components/AppMenuItem.vue';
import AppModalActions from '@/components/modal/AppModalActions.vue';
import AppModalContent from '@/components/modal/AppModalContent.vue';
import AppModalTitle from '@/components/modal/AppModalTitle.vue';
import AppNotification from '@/components/forms/AppNotification.vue';
import AppRadioGroup from '@/components/inputs/AppRadioGroup.vue';
import AppSidebar from '@/admin/AppSidebar.vue';
import AppText from '@/components/AppText.vue';
import AppTextInput from '@/components/inputs/AppTextInput.vue';
import AppIdleWarning from '@/app/AppIdleWarning.vue';
import AppButtonPrimary from '@/components/AppButtonPrimary.vue';
import AppButtonSecondary from '@/components/AppButtonSecondary.vue';
import AppButtonCta from '@/components/AppButtonCta.vue';
import AppChipGroup from '@/components/AppChipGroup.vue';
import AppModal from '@/components/modal/AppModal.vue';
import AppToggle from '@/components/inputs/AppToggle.vue';
import AppTextarea from '@/components/inputs/AppTextarea.vue';
import AppTooltip from '@/components/AppTooltip.vue';

/**
 * Make components available in every vue file without needing to import them explicitly.
 */
export function registerAppComponents(Vue: Application) {
    // forms
    Vue.component('AppFormActions', AppFormActions);
    Vue.component('AppFormActionNotification', AppFormActionNotification);
    Vue.component('AppFormGroup', AppFormGroup);
    Vue.component('AppFormSectionTitle', AppFormSectionTitle);
    Vue.component('AppFormSubtitle', AppFormSubtitle);
    Vue.component('AppFormTitle', AppFormTitle);
    Vue.component('AppNotification', AppNotification);
    // inputs
    Vue.component('AppToggle', AppToggle);
    Vue.component('AppRadioGroup', AppRadioGroup);
    Vue.component('AppTextInput', AppTextInput);
    Vue.component('AppTextarea', AppTextarea);
    // layout
    Vue.component('AppHeader', AppHeader);
    Vue.component('AppMainView', AppMainView);
    Vue.component('AppMainCard', AppMainCard);
    Vue.component('AppSidebar', AppSidebar);
    // modal
    Vue.component('AppModalActions', AppModalActions);
    Vue.component('AppModalContent', AppModalContent);
    Vue.component('AppModalTitle', AppModalTitle);
    Vue.component('AppModal', AppModal);
    // general
    Vue.component('AppButton', AppButton);
    Vue.component('AppButtonCta', AppButtonCta);
    Vue.component('AppButtonPrimary', AppButtonPrimary);
    Vue.component('AppButtonSecondary', AppButtonSecondary);
    Vue.component('AppChipGroup', AppChipGroup);
    Vue.component('AppDivider', AppDivider);
    Vue.component('AppHeading', AppHeading);
    Vue.component('AppHint', AppHint);
    Vue.component('AppLink', AppLink);
    Vue.component('AppLoader', AppLoader);
    Vue.component('AppMenuItem', AppMenuItem);
    Vue.component('AppText', AppText);
    Vue.component('AppIdleWarning', AppIdleWarning);
    Vue.component('AppTooltip', AppTooltip);
}
