import type {
    NavigationGuard,
    NavigationGuardNext,
    RouteRecordRaw,
    RouterScrollBehavior,
} from 'vue-router';
import { createRouter, createWebHistory } from 'vue-router';
import LoginView from '@/app/LoginView.vue';
import PreferencesView from '@/preferences/PreferencesView.vue';
import CaseSettingsView from '@/caseSettings/CaseSettingsView.vue';
import ScansView from '@/scanView/ScanView.vue';
import PlansView from '@/planView/PlansView.vue';
import PlannerView from '@/planner/PlannerView.vue';
import PrivacyView from '@/app/PrivacyView.vue';
import TermsView from '@/app/TermsView.vue';
import AboutView from '@/app/AboutView.vue';
import ForgotPasswordView from '@/app/forgot-password/ForgotPasswordView.vue';
import ResetPasswordView from '@/app/reset-password/ResetPasswordView.vue';
import CaseSearchView from '@/dashboard/CaseSearchView.vue';
import { cachedAuthTokens } from '@/api/cache';
import AdminView from '@/admin/AdminView.vue';
import { useUserStore } from '@/app/userStore/store';
import { identifyUser } from '@/plugins/hotjar';
import { assertNonNull, taggedLogger } from '@/util';
import type { ApiUserObject } from '@/api/user/me';
import ComponentsView from '@/admin/ComponentsView.vue';
import { IN_MAINTENANCE_MODE, MAINTENANCE_REDIRECT } from '@/plugins/maintenanceMode';

const logger = taggedLogger('router');

export enum ROUTES {
    HOME = 'HOME',
    LOGIN = 'LOGIN',
    PREFERENCES = 'PREFERENCES',
    NEW_CASE = 'NEW_CASE',
    EDIT_CASE = 'EDIT_CASE',
    CT_SCAN = 'CT_SCAN',
    PLANNER = 'PLANNER',
    PLANS = 'PLANS',
    ABOUT = 'ABOUT',
    TERMS = 'TERMS',
    PRIVACY = 'PRIVACY',
    FORGOT_PASSWORD = 'FORGOT_PASSWORD',
    RESET_PASSWORD = 'RESET_PASSWORD',
    ADMIN = 'ADMIN',
    COMPONENTS = 'COMPONENTS',
}

export const routes: RouteRecordRaw[] = [
    /**
     * These routes don't require the user to be logged in.
     */
    { path: '/login', name: ROUTES.LOGIN, component: LoginView },
    { path: '/forgot-password', name: ROUTES.FORGOT_PASSWORD, component: ForgotPasswordView },
    { path: '/reset-password', name: ROUTES.RESET_PASSWORD, component: ResetPasswordView },

    /**
     * Must be logged in to access the following routes.
     *
     * User will be redirected to login page if they are not logged in.
     */
    {
        path: '/',
        name: ROUTES.HOME,
        component: CaseSearchView,
        meta: { requiresAuth: true },
    },
    {
        path: '/preferences',
        name: ROUTES.PREFERENCES,
        component: PreferencesView,
        meta: { requiresAuth: true },
    },
    {
        path: '/case/new',
        name: ROUTES.NEW_CASE,
        component: CaseSettingsView,
        meta: { requiresAuth: true },
    },
    {
        path: '/case/:id/edit',
        name: ROUTES.EDIT_CASE,
        component: CaseSettingsView,
        meta: { requiresAuth: true, caseSpecific: true },
    },

    /**
     * These routes are case specific, meaning the page content refers to a particular case.
     *
     * Routes that are case specific have the case navigation in the application header.
     */
    {
        path: '/case/:id/ct-scan',
        name: ROUTES.CT_SCAN,
        component: ScansView,
        meta: { requiresAuth: true, caseSpecific: true },
    },
    {
        path: '/case/:id/planner',
        name: ROUTES.PLANNER,
        component: PlannerView,
        meta: { requiresAuth: true, caseSpecific: true },
    },
    {
        path: '/case/:id/plans',
        name: ROUTES.PLANS,
        component: PlansView,
        meta: { requiresAuth: true, caseSpecific: true },
    },

    /**
     * Static pages accessible from the sidebar.
     */
    {
        path: '/about',
        name: ROUTES.ABOUT,
        component: AboutView,
        meta: { requiresAuth: true },
    },
    {
        path: '/terms-of-use',
        name: ROUTES.TERMS,
        component: TermsView,
        meta: { requiresAuth: true },
    },
    {
        path: '/privacy-policy',
        name: ROUTES.PRIVACY,
        component: PrivacyView,
        meta: { requiresAuth: true },
    },

    {
        path: '/admin/case/:id/',
        name: ROUTES.ADMIN,
        component: AdminView,
        beforeEnter: _verifyAdmin,
        meta: { requiresAuth: true, caseSpecific: true },
    },
    {
        path: '/admin/components',
        name: ROUTES.COMPONENTS,
        component: ComponentsView,
        beforeEnter: _verifyAdmin,
        meta: { requiresAuth: true },
    },
];

export function plannerRoute(caseId: number) {
    return {
        name: ROUTES.PLANNER,
        params: {
            id: caseId,
        },
    };
}

export function adminCaseRoute(caseId: number) {
    return {
        name: ROUTES.ADMIN,
        params: {
            id: caseId,
        },
    };
}

const scrollBehavior: RouterScrollBehavior = (_to, _from, savedPosition) => {
    if (savedPosition) {
        return savedPosition;
    } else {
        return { top: 0 };
    }
};

export const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes,
    scrollBehavior,
});
export default router;

/**
 * Implements software requirement: H1SR-68
 *
 * @link https://formuslabs.youtrack.cloud/issue/H1SR-68/User-can-authenticate-with-their-email-as-a-username-and-their-chosen-password
 *
 * @see user cannot perform any other functionality in the software while unauthenticated
 */

export const requireAuthentication: NavigationGuard = async (to: any, from, next) => {
    const userStore = useUserStore();

    // No authentication required for this route
    if (!to.meta.requiresAuth) {
        // This ensures that user store is empty when user is logged out.
        logger.debug("Clear the user data if it's a public route.");
        userStore.clear();

        return next();
    }

    // it's fine if the user has a valid refresh token,
    // we can use that to automatically obtain a new access token if needed.
    const tokens = await cachedAuthTokens();
    if (!tokens) {
        logger.debug('Could not automatically authenticate user, redirecting to login page');
        return next({ name: ROUTES.LOGIN });
    }

    if (!userStore.user) {
        logger.info('Loading current user. This should be done only once per session.');
        await userStore.load();

        const user = assertNonNull<ApiUserObject | null>(userStore.user, 'user');
        await identifyUser(user);
    } else {
        logger.debug('User already logged in. No need to load user data.');
    }

    return next();
};

export const maintenanceRedirect: NavigationGuard = async (to: any, from: any, next: any) => {
    if (IN_MAINTENANCE_MODE && MAINTENANCE_REDIRECT !== '') {
        console.log('IN_MAINTENANCE_MODE: %s', IN_MAINTENANCE_MODE);
        console.log('MAINTENANCE_REDIRECT: %s', MAINTENANCE_REDIRECT);
        window.location.href = MAINTENANCE_REDIRECT;
    } else {
        next(); // Proceed to the requested route
    }
}

router.beforeEach(maintenanceRedirect)
router.beforeEach(requireAuthentication);

async function _verifyAdmin(_to: any, _from: any, next: NavigationGuardNext): Promise<boolean | void> {
    const store = useUserStore();
    await store.load();

    if (store.isAdmin) {
        return next();
    }

    return false;
}
