<template>
    <div class="container">
        <v-slider
            class="slider mx-1"
            v-model="model"
            :min="min"
            :max="max"
            :step="props.step"
            color="primary"
            track-size="2"
            track-color="grey-light-1"
            track-fill-color="primary"
            thumb-size="12"
            thumb-label="always"
            thumb-color="primary"
            hide-details
            :disabled="disabled"
        />
        <v-btn
            size="48"
            class="plus-minus-button"
            elevation="1"
            @click="decrement"
            :disabled="disabled || minDisabled"
        >
            <v-icon size="24" icon="mdi-minus" color="primary" />
        </v-btn>
        <v-btn
            size="48"
            class="plus-minus-button mr-1"
            elevation="1.5"
            @click="increment"
            :disabled="disabled || maxDisabled"
        >
            <v-icon size="24" icon="mdi-plus" color="primary"></v-icon>
        </v-btn>
    </div>
</template>

<!--suppress CssUnusedSymbol -->
<style scoped>
    .slider {
        align-self: end;
    }

    .container {
        display: flex;
        gap: 15px;
        align-items: center;
    }

    .plus-minus-button {
        padding: 10px;
        border-radius: 16px;
    }

    :deep(.v-slider-track__background) {
        opacity: 1;
    }
</style>

<script setup lang="ts">
    import { taggedLogger } from '@/util';
    import { computed } from 'vue';
    import { floatsApproxEqual } from '@/formus/geometry/approxEquals';

    const log = taggedLogger('planner-slider');

    const props = defineProps({
        min: { type: Number, default: 0 },
        max: { type: Number, default: 100 },
        step: { type: Number, default: 1 },
        disabled: { type: Boolean, default: false },
    });
    const model = defineModel<number>();

    function increment() {
        if (model.value !== undefined) {
            model.value += props.step;

            if (model.value > props.max) {
                model.value = props.max;
                log.debug(`Value ${model.value} is greater than max ${props.max}. Setting to max.`);
            }
        }
    }

    function decrement() {
        if (model.value !== undefined) {
            model.value -= props.step;

            if (model.value < props.min) {
                model.value = props.min;
                log.debug(`Value ${model.value} is less than min ${props.min}. Setting to min.`);
            }
        }
    }

    const minDisabled = computed(() => {
        if (model.value === undefined) {
            return true;
        }

        return floatsApproxEqual(model.value, props.min, 0.01);
    });

    const maxDisabled = computed(() => {
        if (model.value === undefined) {
            return true;
        }

        return floatsApproxEqual(model.value, props.max, 0.01);
    });

    // const minSafeRange = computed(() => {
    //     return '10%';
    // });
    //
    // const maxSafeRange = computed(() => {
    //     return '80%';
    // });
</script>

<style scoped lang="scss">
    //:deep(.v-slider-track__fill) {
    //    inset-inline-start: v-bind(minSafeRange) !important;
    //    width: v-bind(maxSafeRange) !important;
    //}

    :deep(.v-slider-thumb__label) {
        padding-bottom: 0;
        //z-index: 100 !important;
        margin-bottom: -2px;
        background-color: transparent !important;
        //background-color: rgb(var(--v-theme-surface-light)) !important;
        color: rgb(var(--v-theme-secondary-text)) !important;
        //color: rgb(var(--v-theme-primary)) !important;

        &::before {
            content: none;
        }
    }

    .plus-minus-button {
        :deep(.v-ripple__container) {
            opacity: 0.5;
            box-shadow: none;
        }
    }
</style>
