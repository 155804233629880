<template>
    <app-notification
        v-if="store.isCaseDeemedUnsuitable"
        ref="notification"
        data-testid="case-settings-surgeon-unassigned-notification"
        title="This case was deemed as unsuitable."
        message="The surgeon have been unassigned from this case and will not appear in their case list. This case is in read-only mode now."
        variant="info"
        :animate-to="true"
    />

    <CaseSettingsErrorNotification />
</template>

<script setup lang="ts">
    import CaseSettingsErrorNotification from '@/caseSettings/CaseSettingsErrorNotification.vue';
    import { useCaseSettings } from '@/caseSettings/store/store';

    const store = useCaseSettings();
</script>
