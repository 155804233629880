<template>
    <div class="app-text">
        <slot></slot>
    </div>
</template>

<script setup lang="ts">
    import { toRefs } from 'vue';
    export interface Props {
        inline?: boolean;
    }
    const props = defineProps<Props>();
    const { inline } = toRefs(props);

    const bottomMargin = inline.value ? '0' : '16px';
</script>

<style scoped lang="scss">
    .app-text {
        font-size: 0.75rem;
        line-height: 1.25rem;
        font-weight: 500;
        color: rgb(var(--v-theme-secondary));
        margin-bottom: v-bind('bottomMargin');
    }
</style>
