<template>
    <v-row justify="center">
        <app-modal :model-value="isOpen" persistent width="700">
            <v-card v-if="store.hasTokenExpired">
                <app-modal-title>
                    <span class="headline"> Link Expired </span>
                </app-modal-title>

                <app-modal-content> Your reset link has expired.</app-modal-content>

                <app-modal-content>
                    To request another password reset, click SEND NEW LINK below.
                </app-modal-content>

                <app-modal-actions>
                    <v-spacer />
                    <app-button-primary
                        data-testid="forgot-pass-reset-btn"
                        :to="{ name: ROUTES.FORGOT_PASSWORD }"
                    >
                        Send New Link
                    </app-button-primary>
                </app-modal-actions>
            </v-card>
            <v-card v-else>
                <app-modal-title>Set Your Password</app-modal-title>

                <Form v-slot="{ validate, meta, errors }">
                    <app-modal-content>
                        <app-form-group label="New Password" size="fluid">
                            <Field
                                name="password"
                                :rules="{
                                    required: true,
                                    min: 8,
                                    atLeastOneLowercase: true,
                                    atLeastOneUppercase: true,
                                    atLeastOneNumber: true,
                                    atLeastOneSpecialChar: true,
                                }"
                                v-slot="{ field, errors }"
                            >
                                <app-text-input
                                    v-bind="field"
                                    v-model="password"
                                    label="New Password"
                                    prepend-inner-icon="mdi-lock"
                                    clear-icon="mdi-close-circle"
                                    :append-inner-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                    :type="showPassword ? 'text' : 'password'"
                                    clearable
                                    required
                                    autocomplete="new-password"
                                    @click:append-inner="onClickShowPassword"
                                    :error-messages="errors.length ? errors.join(', ') : ''"
                                />
                            </Field>
                        </app-form-group>

                        <app-form-group label="Confirm Password" size="fluid">
                            <Field
                                name="password-confirmation"
                                :rules="{ required: true, confirmed: '@password' }"
                                v-slot="{ field, errors }"
                            >
                                <app-text-input
                                    v-bind="field"
                                    v-model="passwordConfirmation"
                                    label="Confirm New Password"
                                    prepend-inner-icon="mdi-lock"
                                    clear-icon="mdi-close-circle"
                                    :append-inner-icon="
                                        showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'
                                    "
                                    :type="showConfirmPassword ? 'text' : 'password'"
                                    clearable
                                    required
                                    autocomplete="confirm-new-password"
                                    @click:append-inner="onClickShowPasswordConfirmation"
                                    :error-messages="errors.length ? errors[0] : ''"
                                />
                            </Field>
                        </app-form-group>

                        <div class="app-description">
                            Your password must contain:
                            <ul>
                                <li>At least 8 characters</li>
                                <li>One uppercase character</li>
                                <li>One lowercase character</li>
                                <li>One number</li>
                                <li>One special character</li>
                            </ul>
                        </div>

                        <p>
                            If you are having trouble, please
                            <a href="mailto:support@formuslabs.com">contact Formus support.</a>
                        </p>
                    </app-modal-content>
                    <app-modal-actions>
                        <app-button-primary
                            :disabled="!meta.dirty || hasErrors(errors)"
                            @click="onSubmit(validate)"
                        >
                            Set Password
                        </app-button-primary>
                    </app-modal-actions>
                </Form>
            </v-card>
        </app-modal>
    </v-row>
</template>

<script setup lang="ts">
    import { useResetPassword } from '@/app/reset-password/store';
    import { storeToRefs } from 'pinia';
    import { Field, Form } from 'vee-validate';
    import { ROUTES } from '@/router';

    const store = useResetPassword();

    store.init();

    const { password, passwordConfirmation, showConfirmPassword, showPassword } =
        storeToRefs(store);

    /** modal is always open */
    const isOpen = true;

    const onClickShowPasswordConfirmation = () => {
        store.showConfirmPassword = !store.showConfirmPassword;
    };

    const onClickShowPassword = () => {
        store.showPassword = !store.showPassword;
    };

    const onSubmit = async (validate: () => Promise<{ valid: boolean }>) => {
        const result = await validate();
        if (result.valid) {
            await store.doPasswordReset();
        }
    };

    const hasErrors = (errors: {}) => {
        return errors && Object.keys(errors).length > 0;
    };
</script>

<style scoped lang="scss">
    a {
        color: rgb(var(--v-theme-primary));
        text-decoration: underline;
    }

    ul {
        margin: 16px 0;
        padding-left: 40px;
    }

    .app-description {
        padding: 20px 0;
    }
</style>
