<template>
    <v-menu
        max-height="500"
        max-width="500"
        content-class="support-menu"
        transition="fade-transition"
        :open-on-hover="true"
        location="bottom"
        offset="30"
        open-delay="0"
        close-delay="0"
    >
        <template v-slot:activator="{ props }">
            <v-hover>
                <template v-slot:default="{ isHovering, props: hoverProps }">
                    <app-menu-item
                        data-testid="header-support-menu"
                        v-bind="{ ...hoverProps }"
                        :background="isHovering ? 'light' : 'dark'"
                        class="cursor-pointer mr-5"
                        icon="mdi-help-circle-outline"
                        @focus="props.onFocus"
                        @blur="props.onBlur"
                        @mouseenter="props.onMouseenter"
                        @mouseleave="props.onMouseleave"
                    />
                </template>
            </v-hover>
        </template>
        <v-card dense min-width="400" color="dark-blue" class="notifications-card" data-testid="header-support-menu-content">
            <v-list dense class="pa-5 overflow-y-auto">
                <v-list-item
                    href="https://support.formuslabs.com/hc/en-us"
                    target="_blank"
                    data-testid="header-support-menu-knowledge-link"
                >
                    Knowledge Base
                </v-list-item>

                <v-divider v-if="showSurveyLink" />

                <v-list-item
                    v-if="showSurveyLink"
                    @click="onShowSurveyClick"
                    target="_blank"
                    class="font-weight-bold"
                    data-testid="header-support-menu-provide-link"
                >
                    Provide Instant Feedback
                </v-list-item>
            </v-list>
        </v-card>
    </v-menu>
</template>

<script setup lang="ts">
    import Hotjar from '@hotjar/browser';

    import { taggedLogger } from '@/util';
    import { ref, watch } from 'vue';
    import { useRoute } from 'vue-router';

    const log = taggedLogger('support-menu');

    const route = useRoute();

    /**
     * At the moment hotjar does not allow to show a survey multiple times
     * (if the user does not navigate to other or refresh the page)
     */
    const showSurveyLink = ref(true);

    watch(
        route,
        () => {
            showSurveyLink.value = true;
        },
        { immediate: true, deep: true },
    );

    const onShowSurveyClick = () => {
        showSurveyLink.value = false;

        Hotjar.event('show-survey');

        if (import.meta.env.DEV) {
            log.info('survey in local development is not displayed');
        }
    };
</script>

<style scoped lang="scss">
    .support-menu {
        .v-list {
            background: var(--v-dark-blue);
            color: var(--v-planner-grey-lighten-1);
        }
    }

    .survey-container {
        display: none; /* Initially hidden */
        /* Additional styling if needed */
    }
</style>
