<template>
    <span>
        <v-row>
            <span v-if="warnings.hasWarnings" class="warning-badge">
                <v-icon size="25" color="caution">mdi-alert</v-icon>
                <app-tooltip activator="parent">
                    <span>There are spinopelvic calculation warnings </span>
                </app-tooltip>
            </span>
            <SpinopelvicWarningsTable :warnings="warnings" :calculations="calculations" />
        </v-row>
        <v-row>
            <v-data-table
                class="warnings-table"
                :headers="[
                    { title: 'Parameter', value: 'label' },
                    { title: 'Value', value: 'value' },
                ]"
                :items="parameters"
            >
                <template #bottom></template>
            </v-data-table>
        </v-row>
    </span>
</template>

<script setup lang="ts">
    import { computed } from 'vue';
    import SpinopelvicWarningsTable from '@/spinopelvic/components/SpinopelvicWarningsTable.vue';
    import type { SpinopelvicCalculations, SpinopelvicWarnings } from './types';
    import { formatAngle } from '@/formus/geometry/formatMath';
    import { useSpinopelvic } from '@/spinopelvic/store/store';

    defineProps<{
        calculations: SpinopelvicCalculations;
        warnings: SpinopelvicWarnings;
    }>();

    const store = useSpinopelvic();

    const parameters = computed(() => {
        return [
            {
                label: 'Spinopelvic Tilt',
                value: formattedStandingPelvicTilt.value,
            },
            {
                label: 'Pelvic Femoral Angle',
                value: formattedPelvicFemoralAngle.value,
            },
            {
                label: 'Lumbar Lordosis',
                value: formattedLumbarLordosis.value,
            },
            {
                label: 'Sacral Slope',
                value: formattedSacralSlope.value,
            },
            {
                label: 'Pelvic Tilt',
                value: formattedPelvicTilt.value,
            },
        ];
    });

    const formattedStandingPelvicTilt = computed(() =>
        formatAngle(Number(store.measurements.standingPelvicTilt) ?? null),
    );

    const formattedPelvicFemoralAngle = computed(() =>
        formatAngle(Number(store.measurements.pelvicFemoralAngle) ?? null),
    );

    const formattedLumbarLordosis = computed(() =>
        formatAngle(Number(store.measurements.lumbarLordosis) ?? null),
    );

    const formattedSacralSlope = computed(() =>
        formatAngle(Number(store.measurements.sacralSlope) ?? null),
    );

    const formattedPelvicTilt = computed(() =>
        formatAngle(Number(store.measurements.pelvicTilt) ?? null),
    );
</script>

<style scoped lang="scss">
    .warning-badge {
        .v-icon {
            vertical-align: bottom;
        }
    }

    :deep(.v-table) {
        background-color: rgb(var(--v-theme-surface)) !important;
    }

    .warnings-table :deep(table) {
        border-top: none;
        //margin-bottom: 10px;
    }

    .warnings-table :deep(table tbody tr td) {
        color: rgb(var(--v-theme-warning));
        background-color: rgb(var(--v-theme-surface)) !important;
        padding-left: 18px;
    }
</style>
