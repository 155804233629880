<template>
    <v-card
        elevation="0"
        width="100"
        height="50"
        class="plan-detail-item-box px-4 mr-4 d-flex flex-column justify-center align-center"
    >
        <div class="text-secondary-text">{{ props.value }}</div>
        <div class="text-secondary text-caption mt-n1">{{ props.label }}</div>
    </v-card>
</template>

<script setup lang="ts">
    const props = defineProps<{
        label: string;
        value: string;
    }>();
</script>

<style lang="scss" scoped>
    .plan-detail-item-box {
        border: 1px solid;
        border-color: rgb(var(--v-theme-outline)) !important;
    }
</style>
