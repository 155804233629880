<template>
    <div class="spinopelvic-panel-header d-flex pa-0 text-heading-h5 text-secondary-text mt-5 align-center">
        Hip-Spine Assessment
        <span v-if="warnings?.hasWarnings && spinopelvic.hasData" class="ml-2 mb-2">
            <v-icon size="25" color="caution">mdi-alert</v-icon>
            <app-tooltip activator="parent">
                <span> There are spinopelvic calculation warnings </span>
            </app-tooltip>
        </span>
    </div>

    <div class="w-100">
        <SpinopelvicDialog
            v-if="warnings && calculations"
            :calculations="calculations"
            :warnings="warnings"
            :cup-inclination="cupInclination"
            :cup-anteversion="cupAnteversion"
        />
    </div>
    <SpinopelvicWarningsTable
        v-if="warnings && calculations && spinopelvic.hasData"
        :warnings="warnings"
        :calculations="calculations"
        class="pa-0 spinopelvic-expand-panel"
    />

    <app-form-group
        class="spinopelvic-form-control"
        label="Spinopelvic Tilt"
        size="fluid"
        optional
        optional-text="if known"
    >
        <app-text-input
            v-model="state.current.standingPelvicTilt"
            data-testid="case-spinopelvic-standing-pelvic-tilt"
            suffix="deg"
            :disabled="spinopelvic.isSaving"
            :error-messages="showError('standingPelvicTilt') ? INPUT_FIELD_ERROR_MSG : ''"
        />
    </app-form-group>

    <app-form-group
        class="spinopelvic-form-control"
        label="Pelvic Femoral Angle"
        size="fluid"
        optional
        optional-text="if known"
    >
        <app-text-input
            v-model="state.current.pelvicFemoralAngle"
            data-testid="case-spinopelvic-pelvic-femoral-angle"
            suffix="deg"
            :disabled="spinopelvic.isSaving"
            :error-messages="showError('pelvicFemoralAngle') ? INPUT_FIELD_ERROR_MSG : ''"
        />
    </app-form-group>

    <app-form-group
        class="spinopelvic-form-control"
        label="Lumbar Lordosis"
        size="fluid"
        optional
        optional-text="if known"
    >
        <app-text-input
            v-model="state.current.lumbarLordosis"
            data-testid="case-spinopelvic-lumbar-lordosis"
            suffix="deg"
            :disabled="spinopelvic.isSaving"
            :error-messages="showError('lumbarLordosis') ? INPUT_FIELD_ERROR_MSG : ''"
        />
    </app-form-group>

    <app-form-group
        class="spinopelvic-form-control"
        label="Sacral Slope"
        size="fluid"
        optional
        optional-text="if known"
    >
        <app-text-input
            v-model="state.current.sacralSlope"
            data-testid="case-spinopelvic-sacral-slope"
            suffix="deg"
            :disabled="spinopelvic.isSaving"
            :error-messages="showError('sacralSlope') ? INPUT_FIELD_ERROR_MSG : ''"
        />
    </app-form-group>

    <app-form-group
        class="spinopelvic-form-control"
        label="Pelvic Tilt"
        size="fluid"
        optional
        optional-text="if known"
    >
        <app-text-input
            v-model="state.current.pelvicTilt"
            data-testid="case-spinopelvic-pelvic-tilt"
            suffix="deg"
            :disabled="spinopelvic.isSaving"
            :error-messages="showError('pelvicTilt') ? INPUT_FIELD_ERROR_MSG : ''"
        />
    </app-form-group>

    <v-container class="d-flex justify-end px-0" style="padding-right: 0">
        <app-button class="w-100" :disabled="spinopelvic.isSaving || !spinopelvic.isDirty" @click="onSave()">
            Update Spinopelvic
        </app-button>
        <app-tooltip right activator="parent">
            <span> Update spinopelvic data and recalculate analysis </span>
        </app-tooltip>
    </v-container>
</template>
<script lang="ts" setup>
    import { computed, onMounted, ref, watch } from 'vue';
    import assert from 'assert';
    import SpinopelvicWarningsTable from '@/spinopelvic/components/SpinopelvicWarningsTable.vue';
    import {
        type SpinopelvicCalculations,
        type SpinopelvicWarnings,
    } from '@/spinopelvic/components/types';
    import { makeSpinoPelvicCalculations } from '@/spinopelvic/components/calculations';
    import { makeSpinopelvicWarnings } from '@/spinopelvic/components/warnings';
    import { useSpinopelvic } from '@/spinopelvic/store/store';
    import { usePlannerStore } from '@/planner/plannerStore';
    import { useCupOverlayStore } from '@/planner/cupOverlayStore';
    import SpinopelvicDialog from '@/planner/components/spinopelvic/SpinopelvicDialog.vue';
    import { useRoute } from 'vue-router';
    import { verify } from '@/lib/verify';
    import { storeToRefs } from 'pinia';
    import { assertDefined, assertNonNull } from '@/util';
    // import { trackEvent } from '@/analytics/amplitude';
    // import { caseEventProperties } from '@/analytics/event';

    const INPUT_FIELD_ERROR_MSG = 'Please provide a number (2dp). All values must be entered';

    const route = useRoute();
    const plannerStore = usePlannerStore();
    const spinopelvic = useSpinopelvic();

    const { state } = storeToRefs(spinopelvic);

    // TODO: The UX is a bit weird. These variables are initialized as null and then set on mounted
    //       Ideally they should be computed properties and that would make the code cleaner.
    //       The issue is that the current design is that  calculations and warnings are only updated
    //       after an explicit save to the api.
    const calculations = ref<SpinopelvicCalculations | null>(null);
    const warnings = ref<SpinopelvicWarnings | null>(null);

    const cupInclination = computed(() => {
        return assertDefined(useCupOverlayStore().inclination, 'cup-inclination');
    });

    const cupAnteversion = computed(() => {
        return assertDefined(useCupOverlayStore().anteversion, 'cup-anteversion');
    });

    const calculate = () => {
        assert(spinopelvic.isEnabled, 'Spinopelvic feature must be enabled');
        const alignmentMode = assertDefined(plannerStore.case?.alignmentMode, 'alignment-mode');
        const landmarks = assertNonNull(plannerStore.hipSpineLandmarks, 'landmarks');
        const inclination = cupInclination.value;
        const anteversion = cupAnteversion.value;

        calculations.value = makeSpinoPelvicCalculations(
            landmarks,
            spinopelvic.measurements,
            alignmentMode,
        );

        warnings.value = makeSpinopelvicWarnings(
            inclination,
            anteversion,
            spinopelvic.measurements,
            calculations.value,
        );
    };

    const onSave = async () => {
        const caseIdParam = verify(route.params.id, 'case id is required');
        const caseId = Number(caseIdParam);
        await spinopelvic.save(caseId);
        // if (spinopelvicStore.isSubmitted) {
        //     trackEvent('spinopelvic_measurements_saved', {
        //         ...caseEventProperties(hipTemplateStore.project),
        //     });
        // }
    };

    function showError(field: string): boolean {
        return spinopelvic.hasFieldError(field);
    }

    onMounted(calculate);

    watch([() => spinopelvic.measurements, () => plannerStore.template?.cupRotation], calculate);
</script>

<style scoped lang="scss">
    .spinopelvic-expand-panel {
        :deep(label) {
            width: 100px;
            font-size: 12px;
        }

        :deep(span.error-msg) {
            font-size: 12px;
        }
    }

    :deep(.form-group-content) {
        min-width: unset !important;
    }
</style>
