<template>
    <v-icon :icon="icon" :class="cssClass"></v-icon>
</template>

<script setup lang="ts">
    import { computed } from 'vue';
    import type { DicomMessageLevel } from '@/lib/dicom/DicomSeries';

    interface Props {
        level: DicomMessageLevel;
    }
    const props = defineProps<Props>();

    const icon = computed(() => {
        if (props.level >= 400) {
            return 'mdi-information-outline';
        } else if (props.level >= 200) {
            return 'mdi-alert';
        } else {
            return 'mdi-alert-octagon';
        }
    });

    const cssClass = computed(() => {
        if (props.level >= 400) {
            return 'text-info';
        } else if (props.level >= 300) {
            return 'text-caution';
        } else if (props.level >= 200) {
            return 'text-error';
        } else {
            return 'text-red';
        }
    });
</script>

<style scoped>
</style>
