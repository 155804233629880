<template>
    <v-list-item lines="two">
        <template v-slot:prepend>
            <v-icon class="mr-4" size="25" :color="iconColor" style="opacity: 1">
                {{ icon }}
            </v-icon>
        </template>

        <v-list-item-subtitle class="text-on-dark-blue opacity-100" style="opacity: 1">
            {{ value.message }}
        </v-list-item-subtitle>
        <v-list-item-subtitle v-if="value.timestamp" class="planner-grey">
            {{ createdOn }}
        </v-list-item-subtitle>
    </v-list-item>
</template>

<script setup lang="ts">
    import { computed } from 'vue';
    import { DateTime, FixedOffsetZone, type ToRelativeUnit } from 'luxon';
    import { taggedLogger } from '@/util';
    import type { CaseNotification } from '@/api/case/types';

    const log = taggedLogger('notification-list-item');

    const props = defineProps<{
        value: CaseNotification;
    }>();

    const icon = computed(() => {
        switch (props.value.type) {
            case 'Info':
            case 'Warning':
            case 'Error':
                return 'mdi-information';
            default:
                return '';
        }
    });

    const iconColor = computed(() => {
        switch (props.value.type) {
            case 'Info':
                return 'info';
            case 'Warning':
                return 'caution';
            case 'Error':
                return 'error';
            default:
                return '';
        }
    });

    const getDateTimeUnit = (datetime: DateTime): ToRelativeUnit => {
        const now = DateTime.fromMillis(Date.now(), { zone: FixedOffsetZone.utcInstance });
        const ago = now.diff(datetime, ['days', 'hours', 'minutes', 'seconds']);

        switch (true) {
            case ago.days >= 1:
                return 'days';
            case ago.hours > 1:
                return 'hours';
            case ago.minutes >= 1:
                return 'minutes';
            case ago.minutes < 1 && ago.minutes > 0:
                return 'seconds';
            default:
                return 'days';
        }
    };

    const createdOn = computed(() => {
        const timestamp = props.value.timestamp;
        const when = DateTime.fromISO(timestamp);
        if (when) {
            return when.toRelativeCalendar({ unit: getDateTimeUnit(when) });
        } else {
            log.warn('Failed to parse timestamp from notification message');
        }
        return null;
    });
</script>
