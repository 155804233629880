<template>
    <v-container class="pa-0 ma-0">
        <v-row class="pa-0 my-10 mx-10 flex-nowrap">
            <v-col
                cols="8"
                class="d-flex pa-0 pr-5 main-view justify-center"
                :style="mainImageStyle"
            >
                <slot
                    :width="mainImageSquareSideLength"
                    :height="mainImageSquareSideLength"
                    name="main"
                />
            </v-col>
            <v-col cols="4" class="pa-0">
                <v-row class="ma-0">
                    <v-col
                        cols="12"
                        class="pa-5 secondary-view d-flex align-center justify-center"
                        :style="secondaryImageStyle"
                    >
                        <slot
                            :width="secondaryImageSquareSideLength"
                            :height="secondaryImageSquareSideLength"
                            :padding="secondaryImagePadding"
                            name="second"
                        />
                    </v-col>
                    <v-col
                        cols="12"
                        class="mt-5 pa-5 secondary-view d-flex align-center justify-center"
                        :style="secondaryImageStyle"
                    >
                        <slot
                            :width="secondaryImageSquareSideLength"
                            :height="secondaryImageSquareSideLength"
                            :padding="secondaryImagePadding"
                            name="third"
                        />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row class="slider-container ma-0 py-2 px-10">
            <v-col class="pa-0 pt-5">
                <slot name="slider" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
    import { computed, type StyleValue } from 'vue';
    import { useDisplay } from 'vuetify';
    import StyleUtil from '@/scanView/StyleUtil';

    /**
     * A layout for the different dicom images view (axial, coronal, sagittal).
     * This component is highly coupled to the <CatStackImage> component.
     * The idea is to scale (usually down) cat stack images to fit in the layout boxes keeping
     * the aspect ratio of the slices.
     *
     * Difficulty
     * -----------
     * At the moment, the layout is implemented pre-defining the width/height in pixels of the main image
     * for the different media query breakpoints.
     *   1. The main view is a square of side L:
     *
     *   2. S1 (first secondary view) and S2 (second secondary view) are also a square.
     *      Both images are divided by a gap. In css this gap is expressed as a margin.
     *      The length of side of the square of S1 (and S2) is as follows:
     *
     *      SL =  (L - top margin between both images) / 2
     *
     *                   Width = L            SL
     *                ┌────────────────────┐ ┌────────┐
     *                │                    │ │  S1    │                  (zoom into S1/S2)
     *   Height = L   │                    │ │        │ SL             ─────────────────────┐
     *                │        Main        │ └────────┘ ◄─── gap                            │
     *                │        View        │ ┌────────┐                                     │
     *                │                    │ │  S2    │ SL                                  │
     *                │                    │ │        │                                     │
     *                └────────────────────┘ └────────┘                                     │
     *                                         SL                                           │
     *                                                                                      ▼
     *                                                                          ┌──────────────────────┐
     *                                                                          │      Padding         │
     *                                                                          │   ┌──────────────┐   │
     *                                                  The S1/S2 views         │   │              │   │
     *                                                  have a padding          │ P │   S1         │ P │
     *                                                                          │   │              │   │
     *                                                                          │   │              │   │
     *                                                                          │   └──────────────┘   │
     *                                                                          │     Padding          │
     *                                                                          └──────────────────────┘
     */

    const secondaryImagePadding = 20;

    const { name } = useDisplay();

    /** The secondary images width is relative to the main one */
    const secondaryImageSquareSideLength = computed((): number => {
        // Accounts for 20px of margin between images
        // TODO: This could be implement to dynamically get the margin/padding values of the secondary images
        const marginTopBetweenSecondaryImages = 20;

        return (mainImageSquareSideLength.value - marginTopBetweenSecondaryImages) * 0.5;
    });

    const mainImageStyle = computed((): StyleValue => {
        return {
            width: StyleUtil.toWidthOrHeight(mainImageSquareSideLength.value),
            maxWidth: StyleUtil.toWidthOrHeight(mainImageSquareSideLength.value),
            height: StyleUtil.toWidthOrHeight(mainImageSquareSideLength.value),
            maxHeight: StyleUtil.toWidthOrHeight(mainImageSquareSideLength.value),
        };
    });

    const secondaryImageStyle = computed((): StyleValue => {
        return {
            width: StyleUtil.toWidthOrHeight(secondaryImageSquareSideLength.value),
            maxWidth: StyleUtil.toWidthOrHeight(secondaryImageSquareSideLength.value),
            height: StyleUtil.toWidthOrHeight(secondaryImageSquareSideLength.value),
            maxHeight: StyleUtil.toWidthOrHeight(secondaryImageSquareSideLength.value),
        };
    });

    const mainImageSquareSideLength = computed((): number => {
        switch (name.value) {
            case 'xs':
                return 100;
            case 'sm':
                return 150;
            case 'md':
                return 175;
            case 'lg':
                return 512;
            case 'xl':
                return 650;
            default:
                return 512;
        }
    });
</script>

<style scoped lang="scss">
    .main-view {
        background-color: rgb(var(--v-theme-black));
    }

    .secondary-view {
        background-color: #2c2f33;
    }

    .slider-container {
        background-color: #2c2f33;

        :deep(.v-label) {
            color: rgb(var(--v-theme-grey-light));
        }
    }
</style>
