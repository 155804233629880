<template>
    <header>
        <div class="app-logo-manufacturer" v-if="!auth.isLoggedIn">
            <router-link :to="{ name: ROUTES.HOME }">
                <img alt="Formuslabs Logo" src="../assets/images/formus-full-logo.svg" />
            </router-link>
        </div>
        <div class="app-logo-manufacturer" v-if="auth.isLoggedIn">
            <router-link :to="{ name: ROUTES.HOME }" data-testid="formus-home-button">
                <img alt="Formuslabs Emblem" src="../assets/images/formus-emblem.svg" />
            </router-link>
            <v-divider vertical color="white"></v-divider>
            <img alt="ZB Emblem" src="../assets/images/zimmer-emblem.svg" />
        </div>

        <AppHeaderCase v-if="caseSyncStore.isCaseSpecific" />

        <nav v-if="auth.isLoggedIn">
            <CaseNotifications v-if="caseSyncStore.isCaseSpecific" />

            <SupportMenu />

            <ProfileMenu />

            <ThemeMenu v-if="user.isAdmin" />
        </nav>
    </header>
</template>

<script setup lang="ts">
    /**
     * Implements software requirement: H1SR-80, H1SR-85
     *
     * @link https://formuslabs.youtrack.cloud/issue/H1SR-80/The-Home-Button-Formus-Logo-directs-the-user-to-the-dashboard
     * @link https://formuslabs.youtrack.cloud/issue/H1SR-85/Revoke-user-authentication-when-user-clicks-the-logout-button
     */
    import { ROUTES } from '@/router';
    import { useAuth } from '@/app/auth';
    import { onBeforeUnmount } from 'vue';
    import AppHeaderCase from '@/app/AppHeaderCase.vue';
    import { useCaseSyncStore } from '@/app/useCaseSyncStore';
    import CaseNotifications from '@/app/notifications/CaseNotifications.vue';
    import SupportMenu from '@/app/SupportMenu.vue';
    import ProfileMenu from '@/app/ProfileMenu.vue';
    import ThemeMenu from '@/app/ThemeMenu.vue';
    import { useUserStore } from '@/app/userStore/store';

    const auth = useAuth();
    const user = useUserStore();

    const caseSyncStore = useCaseSyncStore();

    onBeforeUnmount(() => {
        caseSyncStore.stop();
    });
</script>

<style scoped lang="scss">
    header {
        background-color: rgb(var(--v-theme-dark-blue));
        //background-color: rgb(var(--v-theme-dark-blue));
        box-shadow: 0 10px 0 #00000005;
        display: flex;
        left: 0;
        width: calc(100% + 0px);
        align-items: center;
        justify-content: space-between;
        max-height: 80px;
        padding: 1.25rem 2.5rem;
    }

    nav {
        display: flex;
    }

    .app-logo-manufacturer {
        display: flex;
        justify-content: start;
        align-items: start;
    }

    .app-logo-manufacturer > * {
        margin: 20px 10px;
    }

    .app-logo-manufacturer :deep(.v-divider--vertical) {
        opacity: 1;
    }

    .app-logo-manufacturer img {
        position: relative;
        top: 4px;
    }
</style>
