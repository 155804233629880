import type { ThemeDefinition } from 'vuetify';

const orange = '#F47920';
const green = '#00B695';
const yellow = '#ffd059';
const blue = '#369eff';
const red = '#C9252C';
const darkBlue = '#152130';
const onDarkBlue = '#E0E0E0';

const onSceneBackground = '#9e9e9e'

export const formusLightTheme: ThemeDefinition = {
    dark: false,
    colors: {

        green: green,
        'green-hover': '#0D9174',

        'grey-1': '#99a4ac',
        'grey-light': '#D9D9D9',
        'grey-light-1': '#d2dcdf',


        'planner-surface': onDarkBlue,
        'on-planner-surface': darkBlue,
        'outline': '#C1C7CE',
        'on-increment-grey': '#d2dcdf',

        orange: orange,
        'orange-hover': '#EA6301',

        red: red,
        'red-hover': '#AD040C',

        // ===============================
        //
        // Semantic colors
        //
        // =================================
        background: '#F0F2F5',

        surface: '#ffffff',
        'on-surface': '#262626',
        'surface-light': '#F8F8F8',

        primary: blue,
        secondary: '#7C8893',

        caution: yellow,
        error: red,
        info: blue,
        success: green,
        warning: orange,

        'call-to-action': green,

        'dark-blue': darkBlue,
        'dark-background': darkBlue,
        'on-dark-background': onDarkBlue,
        'secondary-text': '#3D4D5C',

        // Need to find where 3js color is defined
        'on-scene-background': onSceneBackground,

        white: '#ffffff',
        black: '#3a3e42'
    },
    variables: {
        // We can add CSS variables here (See 'https://developer.mozilla.org/en-US/docs/Web/CSS/--*')
        'v-text-field-border-radius': '22px',
        'v-text-field-border-color': 'rgb(var(--v-theme-grey-light-1))',
    },
} as const;
