<template>
    <app-main-card outlined height="100%" class="dicom-card">
        <v-card-title class="py-0">
            <v-tabs v-model="tabs" align-tabs="end">
                <!-- Up/down axis looking from superial (top) -->
                <v-tab key="axial" href="#axial-tab"> Axial</v-tab>

                <!-- From/back looking from the anterior (front)-->
                <v-tab key="coronal" href="#coronal-tab">
                    Coronal
                </v-tab>

                <!-- left/right looking from the side of the implant (i.e. from the left on left hip/shoulder)-->
                <v-tab key="sagittal" href="#sagittal-tab">
                    Sagittal
                </v-tab>
            </v-tabs>
        </v-card-title>
        <v-card-text>
            <v-window
                v-model="tabs"
                class="fill-height"
                v-if="store.axial && store.coronal && store.sagittal"
            >
                <v-window-item key="axial" value="axial-tab">
                    <DicomViewerLayout>
                        <template #main="{ width, height }">
                            <CatStackImage
                                :container-width="width"
                                :container-height="height"
                                :value="store.axial"
                            />
                        </template>
                        <template #slider>
                            <DicomViewerSlider :value="store.axial" />
                        </template>
                        <template #second="{ width, height, padding }">
                            <CatStackImage
                                :container-width="width"
                                :container-height="height"
                                :value="store.coronal"
                                :padding="padding"
                                indicator-line="horizontal"
                                :indicator-offset="axialIndicatorOffset"
                            />
                        </template>
                        <template #third="{ width, height, padding }">
                            <CatStackImage
                                :container-width="width"
                                :container-height="height"
                                :value="store.sagittal"
                                :padding="padding"
                                indicator-line="horizontal"
                                :indicator-offset="axialIndicatorOffset"
                            />
                        </template>
                    </DicomViewerLayout>
                </v-window-item>
                <v-window-item key="coronal" value="coronal-tab">
                    <DicomViewerLayout>
                        <template #main="{ width, height }">
                            <CatStackImage
                                :container-width="width"
                                :container-height="height"
                                :value="store.coronal"
                            />
                        </template>
                        <template #slider>
                            <DicomViewerSlider :value="store.coronal" />
                        </template>
                        <template #second="{ width, height, padding }">
                            <CatStackImage
                                :container-width="width"
                                :container-height="height"
                                :value="store.axial"
                                :padding="padding"
                                indicator-line="horizontal"
                                :indicator-offset="coronalIndicatorOffset"
                            />
                        </template>
                        <template #third="{ width, height, padding }">
                            <CatStackImage
                                :container-width="width"
                                :container-height="height"
                                :value="store.sagittal"
                                :padding="padding"
                                indicator-line="vertical"
                                :indicator-offset="coronalIndicatorOffset"
                            />
                        </template>
                    </DicomViewerLayout>
                </v-window-item>
                <v-window-item key="sagittal" value="sagittal-tab">
                    <DicomViewerLayout>
                        <template #main="{ width, height }">
                            <CatStackImage
                                :container-width="width"
                                :container-height="height"
                                :value="store.sagittal"
                            />
                        </template>
                        <template #slider>
                            <DicomViewerSlider :value="store.sagittal" />
                        </template>
                        <template #second="{ width, height, padding }">
                            <CatStackImage
                                :container-width="width"
                                :container-height="height"
                                :value="store.axial"
                                :padding="padding"
                                indicator-line="vertical"
                                :indicator-offset="sagittalIndicatorOffset"
                            />
                        </template>
                        <template #third="{ width, height, padding }">
                            <CatStackImage
                                :container-width="width"
                                :container-height="height"
                                :value="store.coronal"
                                :padding="padding"
                                indicator-line="vertical"
                                :indicator-offset="sagittalIndicatorOffset"
                            />
                        </template>
                    </DicomViewerLayout>
                </v-window-item>
            </v-window>
        </v-card-text>
    </app-main-card>
</template>

<script setup lang="ts">
    import { computed, ref } from 'vue';
    import { useDicomStore } from '@/scanView/dicomStore/store';
    import DicomViewerSlider from '@/scanView/DicomViewerSlider.vue';
    import CatStackImage from '@/scanView/CatStackImage.vue';
    import DicomViewerLayout from '@/scanView/DicomViewerLayout.vue';

    const store = useDicomStore();

    const sliceSampling = 2;

    const tabs = ref('axial-tab');

    /**
     * Axial slices are ordered feet to head, opposite to the appearance of the orthogonal slices
     * therefore, we invert the axial index when calculating the indicator line in the orthogonal slices
     */
    const axialIndicatorOffset = computed(() => {
        const axial = store.catStacks.axial;
        return axial ? (axial.count - axial.currentIndex) * sliceSampling : 0;
    });

    const coronalIndicatorOffset = computed(() => {
        const coronal = store.catStacks.coronal;
        return (coronal?.currentIndex ?? 0) * sliceSampling;
    });

    const sagittalIndicatorOffset = computed(() => {
        const sagittal = store.catStacks.sagittal;
        return (sagittal?.currentIndex ?? 0) * sliceSampling;
    });
</script>

<style scoped lang="scss">
    .dicom-card {
        // Using min-content allows the vuetify flex v-tabs to not add extra margin to the right of the layout
        max-width: unset !important;
        width: min-content;

        // Using :deep selector to reach to vuetify v-tabs content
        :deep(.v-slide-group__container) {
            .v-tabs-slider-wrapper {
                transition: none;
                display: none;
            }

            .v-tab {
                background-color: rgb(var(--v-theme-secondary));
                color: rgb(var(--v-theme-grey-light));
                text-transform: capitalize;
                font-size: 16px;

                &.v-tab--selected {
                    background-color: rgb(var(--v-theme-black));

                    .v-tab__slider {
                        display: none !important;
                    }
                }
            }
        }

        :deep(.v-window) {
            background-color: rgb(var(--v-theme-black));;
        }
    }
</style>
