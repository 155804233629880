<template>
    <div class="planner-overlay">
        <PlannerSidebar />

        <div class="bottom-middle-container">
            <visibility-toggle-bar />
        </div>

        <div class="top-right-container">
            <adjustments-measurements />
        </div>

        <div class="bottom-left-container">
            <span class="app-version">
                {{ versionStore.versionWithPrefix }}
            </span>
        </div>

        <div class="bottom-right-container">
            <snap-to-view />
            <catstack-controls />
        </div>

        <slot />
    </div>
</template>

<script setup lang="ts">
    import CatstackControls from './components/CatstackControls.vue';
    import VisibilityToggleBar from '@/planner/components/VisibilityToggleBar.vue';
    import AdjustmentsMeasurements from '@/planner/components/AdjustmentsMeasurements.vue';
    import SnapToView from '@/planner/components/SnapToView.vue';
    import { useVersion } from '@/app/version';
    import PlannerSidebar from '@/planner/components/PlannerSidebar.vue';

    const versionStore = useVersion();
</script>

<!--suppress CssUnusedSymbol -->
<style scoped lang="scss">
    .planner-overlay {
        background: rgb(var(--v-theme-secondary));
    }

    .bottom-middle-container {
        position: absolute;
        bottom: 30px;
        left: calc(50vw - 200px);
        right: calc(50vw - 200px);
        z-index: 10;
    }

    .top-right-container {
        position: absolute;
        top: 0;
        right: 0;
        margin: 20px;
        width: 400px;
        z-index: 10;
    }

    .bottom-left-container {
        position: absolute;
        bottom: 0;
        left: 60px;
        margin: 20px 20px 20px 8px;
        color: white;
        z-index: 10;
    }

    .bottom-right-container {
        position: absolute;
        bottom: 0;
        right: 0;
        margin: 20px;
        color: white;
        z-index: 10;
    }

    .app-version {
        font-size: 0.75rem;
        line-height: 1.25rem;
        font-weight: 500;
        color: rgb(var(--v-theme-secondary));
    }
</style>
