<template>
    <div class="app-hint">
        <div class="mr-2" :class="cssClass">
            <v-icon :icon="hintIcon" />
        </div>
        <slot></slot>
    </div>
</template>

<script setup lang="ts">
    import { computed } from 'vue';

    // TODO: rewrite this component. Delete if possible

    const props = withDefaults(
        defineProps<{
            type?: 'info' | 'warning' | 'caution';
        }>(),
        {
            type: 'info',
        },
    );

    const hintIcon = computed(() => {
        if (props.type === 'caution' || props.type === 'warning') {
            return 'mdi-alert';
        }

        return 'mdi-information-outline';
    });

    const cssClass = computed(() => {
        return {
            'text-caution': props.type === 'caution',
            'text-info': props.type === 'info',
            'text-warning': props.type === 'warning',
        };
    });
</script>

<style scoped>
    .app-hint {
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>
