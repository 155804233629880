<template>
    <h1 class="text-secondary-text"><slot></slot></h1>
</template>

<style scoped>
    h1 {
        font-size: 28px;
        line-height: 46px;
        font-weight: 500;
        margin: 0 0 20px;
    }
</style>
