import {
    OVER_PI_LL_CSI_LOWER_BOUND,
    OVER_PI_LL_CSI_UPPER_BOUND,
    PI_LL_LIMIT,
    UNDER_PI_LL_CSI_LOWER_BOUND,
    UNDER_PI_LL_CSI_UPPER_BOUND,
} from '@/spinopelvic/components/constants';
import { MathUtils, Vector3 } from 'three';
import {
    type SpinopelvicCalculations,
    type SpinopelvicMeasurements,
} from '@/spinopelvic/components/types';
import { normalize } from '@/formus/geometry/vector3';
import type { AlignmentMode } from '@/formus/anatomy/pelvis/alignment';
import { taggedLogger } from '@/util';
import { findLandmark, type Landmark } from '@/formus/anatomy/landmarks';
import type { ApiStudyLandmarks } from '@/api/study/studyLandmarks';

const log = taggedLogger('spinopelvic-calculation');

export const pelvicIncidence = (standingPelvicTilt: number, sacralSlope: number): number => {
    return standingPelvicTilt + sacralSlope;
};

export const lumboPelvicMismatch = (pi: number, lumbarLordosis: number): number => {
    return pi - lumbarLordosis;
};

export const combinedSagittalIndexLowerBound = (piLLMismatch: number): number => {
    return piLLMismatch > PI_LL_LIMIT ? OVER_PI_LL_CSI_LOWER_BOUND : UNDER_PI_LL_CSI_LOWER_BOUND;
};

export const combinedSagittalIndexUpperBound = (piLLMismatch: number): number => {
    return piLLMismatch > PI_LL_LIMIT ? OVER_PI_LL_CSI_UPPER_BOUND : UNDER_PI_LL_CSI_UPPER_BOUND;
};

export const csiLowerBound = (measurements: SpinopelvicMeasurements): number => {
    const pi = pelvicIncidence(measurements.standingPelvicTilt, measurements.sacralSlope);
    const piLLMismatch = lumboPelvicMismatch(pi, measurements.lumbarLordosis);
    const csiLowerBound = combinedSagittalIndexLowerBound(piLLMismatch);
    return csiLowerBound - measurements.pelvicFemoralAngle;
};

export const csiUpperBound = (measurements: SpinopelvicMeasurements): number => {
    const pi = pelvicIncidence(measurements.standingPelvicTilt, measurements.sacralSlope);
    const piLLMismatch = lumboPelvicMismatch(pi, measurements.lumbarLordosis);
    const csiUpperBound = combinedSagittalIndexUpperBound(piLLMismatch);
    return csiUpperBound - measurements.pelvicFemoralAngle;
};

export const anteversionFromAIRI = (
    inclinationDeg: number,
    anteInclinationDeg: number,
    pelvicTiltDeg: number,
): number => {
    // Calculate anteversion from ante-inclination rotated by pelvic tilt
    const aiRad = MathUtils.degToRad(anteInclinationDeg - pelvicTiltDeg);
    const inclinationRad = MathUtils.degToRad(inclinationDeg);
    const anteversionRad = Math.atan(Math.tan(aiRad) * Math.cos(inclinationRad));
    return Number(MathUtils.radToDeg(anteversionRad).toFixed(2));
};

export const anteversionRotated = (
    inclinationDeg: number,
    anteversionDeg: number,
    pelvicTiltDeg: number,
): number => {
    // Calculate anteversion rotated by pelvic tilt
    const anteversionRad = MathUtils.degToRad(anteversionDeg);
    const inclinationRad = MathUtils.degToRad(inclinationDeg);
    const pelvicTiltRad = MathUtils.degToRad(pelvicTiltDeg);
    const y =
        Math.sin(anteversionRad) * Math.cos(pelvicTiltRad) -
        Math.cos(anteversionRad) * Math.cos(inclinationRad) * Math.sin(pelvicTiltRad);
    return Number(MathUtils.radToDeg(Math.asin(y)).toFixed(2));
};

export const inclinationRotated = (
    inclinationDeg: number,
    anteversionDeg: number,
    pelvicTiltDeg: number,
): number => {
    // Calculate inclination rotated by pelvic tilt
    const inclinationRad = MathUtils.degToRad(inclinationDeg);
    const anteversionRad = MathUtils.degToRad(anteversionDeg);
    const pelvicTiltRad = MathUtils.degToRad(pelvicTiltDeg);

    const x = Math.cos(anteversionRad) * Math.sin(inclinationRad);
    const z =
        Math.sin(anteversionRad) * Math.sin(pelvicTiltRad) +
        Math.cos(anteversionRad) * Math.cos(inclinationRad) * Math.cos(pelvicTiltRad);
    return Number(MathUtils.radToDeg(Math.atan(Math.abs(x) / Math.abs(z))).toFixed(2));
};

export const calcSupinePelvicTilt = (
    leftASIS: Vector3,
    rightASIS: Vector3,
    leftPS: Vector3,
    rightPS: Vector3,
): number => {
    // The input landmarks are represented in supine CT coordinate system
    // The supine CT coordinate system is supposed to be LPS (left-posterior-superior)

    const CoronalPlaneNormal = new Vector3(0, -1, 0);
    const LeftToRight = new Vector3();
    LeftToRight.addVectors(rightASIS, leftASIS.clone().multiplyScalar(-1));
    // If LeftToRight is not parallel to the CT coronal plane, that means the patient
    // rolled to one side. Need to adjust for this
    const AdjustedCoronalPlaneNormal = normalize(
        LeftToRight.cross(CoronalPlaneNormal.cross(LeftToRight)),
    );

    let mostAnteriorPS = new Vector3();
    if (leftPS.y < rightPS.y) {
        mostAnteriorPS = leftPS;
    } else {
        mostAnteriorPS = rightPS;
    }
    const vector1 = new Vector3();
    vector1.addVectors(leftASIS, mostAnteriorPS.clone().multiplyScalar(-1));
    const vector2 = new Vector3();
    vector2.addVectors(rightASIS, mostAnteriorPS.clone().multiplyScalar(-1));
    const APPNormal = normalize(vector1.cross(vector2));

    const supinePT = Math.acos(APPNormal.dot(AdjustedCoronalPlaneNormal));
    let tilt = Number(MathUtils.radToDeg(supinePT).toFixed(2));
    // make the sign correct
    if (leftASIS.dot(AdjustedCoronalPlaneNormal) < mostAnteriorPS.dot(AdjustedCoronalPlaneNormal)) {
        tilt *= -1;
    }
    return tilt;
};

export const calcPT = (
    group: SpinoPelvicLandmarks,
    pelvicTilt: number,
    alignmentMode: AlignmentMode,
): number => {
    // Calculate the true pelvic tilt for spinopelvic, determined by the user's preferred alignment coordinate system
    // We expect the user input patient pelvic tilt value is in APP coordinate system, therefore we only need to be
    // concerned if the user's preference is in CT alignment mode, then the supine pelvic tilt angle is needed.
    log.info(`Calculate pelvic tilt, alignment mode: ${alignmentMode}`);
    if (alignmentMode === 'CT') {
        log.info('Make hip-spine calculations in CT coordinate.');
        const { leftAsis, rightAsis, leftPS, rightPS } = group;
        const supinePT = calcSupinePelvicTilt(
            new Vector3(leftAsis.coordinates.x, leftAsis.coordinates.y, leftAsis.coordinates.z),
            new Vector3(rightAsis.coordinates.x, rightAsis.coordinates.y, rightAsis.coordinates.z),
            new Vector3(leftPS.coordinates.x, leftPS.coordinates.y, leftPS.coordinates.z),
            new Vector3(rightPS.coordinates.x, rightPS.coordinates.y, rightPS.coordinates.z),
        );
        log.info(`supinePT: ${supinePT}`);
        log.info(`pelvicTilt: ${pelvicTilt}`);
        return pelvicTilt - supinePT;
    } else if (alignmentMode === 'APP') {
        log.info('Make hip-spine calculations in APP coordinate system.');
        log.info(`pelvicTilt: ${pelvicTilt}`);
        return pelvicTilt;
    } else {
        throw new Error('Failed to get alignment mode.');
    }
};

export const makeSpinoPelvicCalculations = (
    landmarks: SpinoPelvicLandmarks,
    measurements: SpinopelvicMeasurements,
    alignmentMode: AlignmentMode,
): SpinopelvicCalculations => {
    return {
        pelvicTilt: calcPT(landmarks, measurements.pelvicTilt, alignmentMode),
        csiLowerBound: csiLowerBound(measurements),
        csiUpperBound: csiUpperBound(measurements),
    };
};

export type SpinoPelvicLandmarks = {
    leftAsis: Landmark;
    rightAsis: Landmark;
    leftPS: Landmark;
    rightPS: Landmark;
};

export const makeSpinoPelvicLandmarks = (landmarks: ApiStudyLandmarks): SpinoPelvicLandmarks => {
    const group = landmarks.groups;
    return {
        leftAsis: findLandmark(group, 'pelvis', 'lasis'),
        rightAsis: findLandmark(group, 'pelvis', 'rasis'),
        leftPS: findLandmark(group, 'pelvis', 'lps'),
        rightPS: findLandmark(group, 'pelvis', 'rpt'),
    };
};
