<template>
    <app-modal width="800" :model-value="upload.isPatientDicomMismatch" :persistent="true">
        <v-card>
            <app-modal-title @click="upload.cancel">
                Please confirm: DICOM patient details different from entered patient details
            </app-modal-title>

            <app-modal-content>
                <p>
                    We noticed while processing your DICOM files that the patient details contained
                    in the DICOM data are different from the details entered for the patient for
                    this case.
                </p>

                <v-table class="my-10 mx-0">
                    <thead>
                        <tr>
                            <th class="text-left">Label</th>
                            <th class="text-left">Formus Case patient details</th>
                            <th class="text-left">DICOM patient details</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Name</td>
                            <td>{{ formPatientName }}</td>
                            <td :class="classFor(PatientComparisonErrorType.Name)">
                                {{ uploadPatientName }}
                            </td>
                        </tr>
                        <tr>
                            <td>Sex</td>
                            <td>{{ caseSettings.patient.sex }}</td>
                            <td :class="classFor(PatientComparisonErrorType.Sex)">
                                {{ upload.patient?.sex }}
                            </td>
                        </tr>
                        <tr>
                            <td>Date of birth</td>
                            <td>{{ caseSettings.patient.dateOfBirth }}</td>
                            <td :class="classFor(PatientComparisonErrorType.DateOfBirth)">
                                {{ upload.patient?.birth_date }}
                            </td>
                        </tr>
                    </tbody>
                </v-table>

                <p>
                    Please confirm the correct DICOM files have been uploaded by clicking
                    <strong>’Proceed Anyway’</strong>, if not please click
                    <strong>’Cancel’</strong> and upload the correct files or update the patient
                    details.
                </p>
            </app-modal-content>

            <app-modal-actions>
                <app-button-secondary width="100" class="mr-5" @click="upload.cancel">
                    Cancel
                </app-button-secondary>
                <app-button-cta @click="upload.uploadAnyway">Proceed Anyway</app-button-cta>
            </app-modal-actions>
        </v-card>
    </app-modal>
</template>

<script setup lang="ts">
    import { computed } from 'vue';
    import AppModalTitle from '@/components/modal/AppModalTitle.vue';
    import AppModalContent from '@/components/modal/AppModalContent.vue';
    import AppModalActions from '@/components/modal/AppModalActions.vue';
    import { useCaseSettings } from '@/caseSettings/store/store';
    import { NameUtil } from '@/lib/NameUtil';
    import { useCaseUpload } from '@/caseSettings/case-upload/store';
    import { PatientComparisonErrorType } from '@/lib/dicom/PatientComparisonUtil';

    const caseSettings = useCaseSettings();
    const upload = useCaseUpload();

    const formPatientName = computed(() =>
        NameUtil.format({
            given: caseSettings.patient.firstName,
            middle: caseSettings.patient.middleNames,
            family: caseSettings.patient.familyName,
        }),
    );
    const uploadPatientName = computed(() => NameUtil.format(upload.patient?.name ?? {}));

    const classFor = (key: PatientComparisonErrorType) => {
        const errorFields = upload.patientComparison?.errorFields;
        if (errorFields) {
            return errorFields.find((o) => o.type === key) ? 'highlight-difference' : '';
        }

        return '';
    };
</script>

<style lang="scss" scoped>
    .highlight-difference {
        color: rgb(var(--v-theme-error));
        text-decoration: underline wavy rgb(var(--v-theme-error));
    }
</style>
