<template>
    <div class="adjustments">
        <div class="title">Adjustments</div>
        <div class="group" v-for="(adjustment, key) in adjustments" :key="`${key}`">
            <AdjustmentMeasurement :adjustment="adjustment" :id="key" />
        </div>
    </div>
    <div class="measurements">
        <div class="title">Native Measurements</div>
        <div class="group" v-for="(text, label) in nativeMeasurements" :key="`${label}-${text}`">
            <div class="group-value">{{ text }}</div>
            <div class="group-label">{{ label }}</div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { formatPercentageNumber } from '@/lib/format/formatPercentageNumber';
    import { computed } from 'vue';
    import { formatAngle } from '@/lib/format/formatAngle';
    import { usePlannerStore } from '@/planner/plannerStore';
    import { useCupOverlayStore } from '@/planner/cupOverlayStore';
    import { useStemOverlayStore } from '@/planner/stemOverlayStore';
    import { formatLengthSign } from '@/lib/format/formatLengthSign';
    import {
        type AdjustmentInfo,
        type AdjustmentKey,
        createAdjustment,
    } from '@/planner/components/adjustments';
    import AdjustmentMeasurement from '@/planner/components/AdjustmentMeasurement.vue';

    const plannerStore = usePlannerStore();
    const cupStore = useCupOverlayStore();
    const stemStore = useStemOverlayStore();

    function formattedCoverage(): string {
        const coverage = cupStore.coverage;
        return coverage === null || coverage === 'calculating'
            ? '--'
            : formatPercentageNumber(coverage);
    }

    const legLengthAdjustment = computed(() => {
        const value = plannerStore.adjustments?.legLength;
        const target = plannerStore.targetAdjustments?.legLength;

        return createAdjustment(
            'Leg length',
            formatLengthSign,
            value === undefined || target === undefined
                ? null
                : {
                      calculating: plannerStore.currentOperation === 'set-targets',
                      warning: Math.abs(value - target) > 10,
                      value,
                  },
        );
    });

    const offsetAdjustment = computed(() => {
        const value = plannerStore.adjustments?.offset;
        const target = plannerStore.targetAdjustments?.offset;

        return createAdjustment(
            'Leg offset',
            formatLengthSign,
            value === undefined || target === undefined
                ? null
                : {
                      calculating: plannerStore.currentOperation === 'set-targets',
                      warning: Math.abs(value - target) > 10,
                      value,
                  },
        );
    });

    const cupCoverageAdjustment = computed(() => {
        const coverage = cupStore.coverage;
        const calculating = coverage === 'calculating';

        if (calculating) {
            return createAdjustment('Cup coverage', formattedCoverage, {
                calculating: calculating,
                warning: false,
                value: null,
            });
        } else {
            return createAdjustment('Cup coverage', formattedCoverage, {
                calculating: calculating,
                warning: coverage === null ? false : coverage < 0.5,
                value: coverage ?? null,
            });
        }
    });

    const adjustments = computed(
        (): Record<AdjustmentKey, AdjustmentInfo> => ({
            'stem-atv': createAdjustment('Stem atv.', formatAngle, stemStore.stemAnteversion),
            'leg-length': legLengthAdjustment.value,
            'leg-offset': offsetAdjustment.value,

            // plain number, no calculating or warning state
            'cup-atv': createAdjustment('Cup Atv.', formatAngle, cupStore.anteversion),

            // plain number, no calculating or warning state
            'cup-inc': createAdjustment('Cup Inc.', formatAngle, cupStore.inclination),
            'cup-coverage': cupCoverageAdjustment.value,
        }),
    );

    const nativeMeasurements = computed(() => ({
        'Hip Length Diff.': formatLengthSign(plannerStore.nativeMeasurements.hipLengthDifference),
        'Femur Atv.': formatAngle(plannerStore.nativeMeasurements.femoralAnteversion),
        'Acetabular Atv.': formatAngle(plannerStore.nativeMeasurements.acetabularAnteversion),
        'Acetabular Inc.': formatAngle(plannerStore.nativeMeasurements.acetabularInclination),
    }));
</script>

<style scoped lang="scss">
    .title {
        font-size: 0.75rem;
        font-weight: 500;
        letter-spacing: 0.1666666667em;
        line-height: 2rem;
        text-transform: uppercase;
        color: rgb(var(--v-theme-white));
        width: 100%;
    }

    .adjustments,
    .measurements {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 40px;
    }

    .measurements {
        .group {
            flex-basis: 50%;
        }
    }

    .group {
        display: flex;
        flex-direction: column;
        flex-basis: 33%;
        margin-bottom: 10px;
    }

    .group-value {
        font-weight: 700;
        font-size: 1rem;
        letter-spacing: 0.009375em;
        line-height: 1.75rem;
        color: rgb(var(--v-theme-white));
    }

    .group-label {
        font-size: 0.75rem;
        letter-spacing: 0.0333333333em;
        line-height: 1.25rem;
        color: rgb(var(--v-theme-on-scene-background));
    }
</style>
