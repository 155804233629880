import { parseHeadMetaTag } from '@/lib/headMetaTags';

function maintenanceMode(): string {
    return parseHeadMetaTag('maintenance-mode', 'false');
}

function maintenanceRedirect(): string {
    return parseHeadMetaTag('maintenance-redirect', '');
}

export const IN_MAINTENANCE_MODE =  maintenanceMode() === 'true';
export const MAINTENANCE_REDIRECT = maintenanceRedirect();
